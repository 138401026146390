import React from "react"
import AlbumText from "../../components/AlbumText"
import Layout from "../../components/Layout"
import People from "../../components/People"
import Tracks from "../../components/Tracks"
import colors from "../../colors"
import { getAlbum } from "../../data/albums"
import AlbumCover from "../../components/AlbumCover"
import Title from "../../components/Title"

const dwarves = getAlbum("baked-dwarves")

export default () => (
  <Layout backgroundColor={colors.pink} textColor={colors.white}>
    <AlbumCover filename="transparent-logo.png" />
    <Title color={colors.yellow} shadowColor={colors.black}>
      Baked Dwarves Moan At Harvestman
    </Title>
    <AlbumText>
      <p>
        Release Date: <strong>May 26th, 2020</strong>
      </p>
      <p>
        <strong>Baked Dwarves Moan At Harvestman</strong> is the debut EP from{" "}
        <strong>The Wig Out</strong>, a new project formed in March 2020 by{" "}
        <strong>Dave Foley</strong> (
        <a href="https://hypatialake.com">Hypatia Lake</a>) and{" "}
        <strong>Adam Pearson</strong> (
        <a href="https://soundcloud.com/swift-tuttle">Swift-Tuttle</a>).
      </p>
      <p>
        Adapting creative constraint practices like exquisite corpse and
        conditional drawing to our pandemic-imposed isolation, The Wig Out
        follows a unique process. Each release is recorded and mixed in a period
        of four weeks, and features a new lineup of musicians. Each member of
        the group works on one of the songs each week, adding, subtracting,
        shaping, or mutilating as they see fit, before passing it on to the next
        person. As a result, there’s something for everyone on this album.
      </p>
      <p>
        <strong>Curvature</strong> is a beat-heavy homage to My Bloody
        Valentine, whereas
        <strong> Running on the Yellow Line</strong> comes off as a collab
        between Why? and Sebadoh.{" "}
        <strong>What’s Up With Those Cop Shoes?</strong> could be the missing
        Meat Beat Manifesto track from the original Matrix soundtrack.
      </p>
    </AlbumText>
    <Title>Tracks</Title>
    <p>All songs are free of cussing!</p>
    <Tracks tracks={dwarves.tracks} />
    <Title>People</Title>
    <AlbumText>Recorded and mixed in March and April 2020 by:</AlbumText>
    <People album={dwarves} />
  </Layout>
)
